// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../Assets/Image/infrabanner.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".infra-header {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    min-height: 500px;\n\n  /* Center and scale the image nicely */\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  position: relative;\n\n  \n  }\n\n  .infratext{\n    font-family:\" normal normal bold 36px/87px Poppins\";\nletter-spacing: 0px;\ncolor: #707070;\nopacity: 1;\nfont-size: 20px;\n\n  }", "",{"version":3,"sources":["webpack://./src/Page/OEM/ItInfra/infra.css"],"names":[],"mappings":"AAAA;IACI,yDAA8D;IAC9D,iBAAiB;;EAEnB,sCAAsC;EACtC,2BAA2B;EAC3B,4BAA4B;EAC5B,sBAAsB;EACtB,kBAAkB;;;EAGlB;;EAEA;IACE,mDAAmD;AACvD,mBAAmB;AACnB,cAAc;AACd,UAAU;AACV,eAAe;;EAEb","sourcesContent":[".infra-header {\n    background-image: url(\"../../../Assets/Image/infrabanner.jpg\");\n    min-height: 500px;\n\n  /* Center and scale the image nicely */\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  position: relative;\n\n  \n  }\n\n  .infratext{\n    font-family:\" normal normal bold 36px/87px Poppins\";\nletter-spacing: 0px;\ncolor: #707070;\nopacity: 1;\nfont-size: 20px;\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
