// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/Image/bg-sand-wave.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Contentdevlop {\n    background-image: url(\"https://img.freepik.com/free-vector/stylish-glowing-digital-red-lines-banner_1017-23964.jpg?w=1380&t=st=1664435631~exp=1664436231~hmac=cef60af99d2dd2dfcb9a256bed0e817437cce1d3ae8178afc8d80d6b7696bcd8\");\n    height: 450px;\n    }\n    .contentback{\n        background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n        background-repeat: no-repeat;\n        min-height: 300px;\n     \n    /* \n        background-position: right 0 bottom 0; */\n        background-size: 100%;\n    }\n    ", "",{"version":3,"sources":["webpack://./src/Page/Contentdevlopment/Contentdevlopment.css"],"names":[],"mappings":"AAAA;IACI,gOAAgO;IAChO,aAAa;IACb;IACA;QACI,yDAA4D;QAC5D,4BAA4B;QAC5B,iBAAiB;;IAErB;gDAC4C;QACxC,qBAAqB;IACzB","sourcesContent":[".Contentdevlop {\n    background-image: url(\"https://img.freepik.com/free-vector/stylish-glowing-digital-red-lines-banner_1017-23964.jpg?w=1380&t=st=1664435631~exp=1664436231~hmac=cef60af99d2dd2dfcb9a256bed0e817437cce1d3ae8178afc8d80d6b7696bcd8\");\n    height: 450px;\n    }\n    .contentback{\n        background-image: url(\"../../Assets/Image/bg-sand-wave.png\");\n        background-repeat: no-repeat;\n        min-height: 300px;\n     \n    /* \n        background-position: right 0 bottom 0; */\n        background-size: 100%;\n    }\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
